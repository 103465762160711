<template>
  <div class="card card-custom card-transparent">
   
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-4"
        id="kt_wizard_v4"
        data-wizard-state="step-first"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps" style="justify-content: left;">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
              @click="stepOne()"
            >
              <div class="wizard-wrapper">
                <div class="wizard-number">
                  1
                </div>
                <div class="wizard-label">
                  <div class="wizard-title">
                    Data Perseroan
                  </div>
                  <div class="wizard-desc">
                    Tambah Data Perseroan
                  </div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step" @click="stepTwo()">
              <div class="wizard-wrapper">
                <div class="wizard-number">
                  2
                </div>
                <div class="wizard-label">
                  <div class="wizard-title">
                    Dokumen
                  </div>
                  <div class="wizard-desc">
                    Tambah Dokumen
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <v-app>
          <div class="card card-custom card-shadowless rounded-top-0">
            <div class="card-body p-0">
              <div class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                <div class="col-xl-12 col-xxl-12">
                  <!--begin: Wizard Form-->
                  <!-- <form class="form mt-0 mt-lg-10" id="kt_form"> -->
                    <v-progress-linear
                      indeterminate
                      color="pink darken-2"
                      v-show="!loadForm && pageOne"
                      style="height:15px;"
                    ><div><span>Proses Data...</span></div></v-progress-linear>
                    <!--begin: Wizard Step 1-->
                  <v-form ref="form" v-model="valid" v-show="loadForm" lazy-validation>
                    <div
                      class="pb-5"
                      data-wizard-type="step-content"
                      data-wizard-state="current"
                    >
                    <div class="row">
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Nama</label>
                        <v-text-field
                          v-model="formItem.nama"
                          :rules="idFormBiasa"
                          id="nama"
                          label="Nama"
                          clearable
                          required
                          solo
                        ></v-text-field>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Jenis Perseroan</label>
                        <v-select
                            v-model="formItem.jenis_perseroan"
                            :items="itemJenis"
                            :rules="idFormBiasa"
                            item-value="id"
                            item-text="jenis_perseroan"
                            label="Jenis Perseroan"
                            clearable
                            solo
                            required
                        ></v-select>
                      </div>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group row align-items-center" style="width: 200px;">
                        <div class="col-md-5 my-auto pb-0"><span>Status</span></div>
                        <div class="col-md-1 my-auto">
                          <v-checkbox
                            v-model="formItem.status"
                            label="Aktif"
                            color="red darken-3"
                            hide-details
                          ></v-checkbox>
                        </div>
                      </div>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Alamat</label>
                        <v-textarea
                          v-model="formItem.alamat"
                          label="Alamat"
                          clearable
                          solo
                          ></v-textarea>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Nama Kontak</label>
                        <v-text-field
                          v-model="formItem.contact"
                          id="contact"
                          label="Nama Kontak"
                          clearable
                          solo
                        ></v-text-field>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Email</label>
                        <v-text-field
                          v-model="formItem.email"
                          id="email"
                          label="Email"
                          clearable
                          solo
                        ></v-text-field>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Nomor Telepon/HP</label>
                        <v-text-field
                          v-model="formItem.no_hp"
                          type="number"
                          id="no_hp"
                          label="Nomor Telepon/HP"
                          clearable
                          solo
                        ></v-text-field>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Nomor Akte</label>
                        <v-text-field
                          v-model="formItem.no_akte"
                          id="no_akte"
                          label="Nomor Akte"
                          clearable
                          solo
                        ></v-text-field>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Tanggal Akte</label>
                        <v-menu
                          v-model="menu2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="formItem.tgl_akte_baru"
                              label="Tanggal Akte"
                              prepend-icon="mdi-calendar"
                              solo
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="tgl_akte_sementara"
                            @input="menu2 = false"
                            id="tgl_akte"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>NPWP</label>
                        <v-text-field
                          v-model="formItem.npwp"
                          id="npwp"
                          label="NPWP"
                          clearable
                          solo
                        ></v-text-field>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>NIB</label>
                        <v-text-field
                          v-model="formItem.nib"
                          id="nib"
                          label="NIB"
                          clearable
                          solo
                        ></v-text-field>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>SK / SP</label>
                        <v-text-field
                          v-model="formItem.no_sk"
                          id="no_sk"
                          label="SK / SP"
                          clearable
                          solo
                        ></v-text-field>
                      </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Tanggal SK / SP</label>
                        <v-menu
                          v-model="menu3"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="formItem.tgl_sk_baru"
                              label="Tanggal SK / SP"
                              prepend-icon="mdi-calendar"
                              solo
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="tgl_sk_sementara"
                            @input="menu3 = false"
                            id="tgl_akte"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                      </v-col>
                      <!-- <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group">
                        <label>Tanggal Berakhir Masa Kepengurusan</label>
                        <v-menu
                          v-model="menu4"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="formItem.tgl_akhir_akte_baru"
                              label="Tanggal Berakhir Masa Kepengurusan"
                              prepend-icon="mdi-calendar"
                              solo
                              required
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="tgl_akhir_akte_sementara"
                            @input="menu4 = false"
                            id="tgl_akhir_akte"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                      </v-col> -->
                      <!-- <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="pb-0 mb-0"
                      >
                      <div class="form-group row align-items-center">
                        <div class="col-md-5 my-auto pb-0"><span>Ingatkan Tgl Berakhir</span></div>
                        <div class="col-md-1 my-auto">
                          <v-checkbox
                            v-model="formItem.reminder"
                            label="Aktifkan"
                            color="red darken-3"
                            hide-details
                          ></v-checkbox>
                        </div>
                      </div>
                      </v-col> -->
                    </div>
                    </div>
                  </v-form>
                <!-- </div>
              </div> -->
                    <!--end: Wizard Step 1-->
              <!-- <div class="row justify-content-center py-4 px-4 py-lg-7 px-lg-5">
                <div class="col-xl-12 col-xxl-7"> -->
                    <!--begin: Wizard Step 2-->
                    <div class="pb-5" data-wizard-type="step-content">
                      <!-- <div class="mb-10 font-weight-bold text-dark">
                        Setup Your Address
                      </div> -->
                      <v-data-table
                        :headers="headerDokumen"
                        :items="dDokumen"
                        :options.sync="options"
                        :server-items-length="tdDokumen"
                        :loading="loadingTable"
                        loading-text="Proses Data..."
                        class="elevation-1"
                        :footer-props="{
                            'items-per-page-text':'Data Per Halaman',
                            'items-per-page-options': [5, 25, 50, 100]
                        }"
                      >
                      <template v-slot:[`item.number`]="{ index }">
                        <td align="center">{{(options.page * options.itemsPerPage) - options.itemsPerPage + (index + 1)}}</td>
                      </template>
                      <template v-slot:[`item.reminder`]="{ item }">
                        <v-chip
                          color="#8b728e"
                          dark
                        >
                          {{ item.reminder_fe }}
                        </v-chip>
                      </template>
                      <template v-slot:[`item.nama_file`]="{ item }">
                        <v-btn
                          v-show="item.buttonTable"
                          color="#efcfe3"
                          @click="openWeb(item.nama_file)"
                        ><span>Buka File</span></v-btn>
                          <!-- <td v-if="item.source_type_fe == 'RFID'" align="center">{{item.source}}</td> -->
                            <!-- <vue-pdf-app :pdf="baseUrlUpload + '/' +item.source"></vue-pdf-app> -->
                      </template>
                        <template v-slot:top>
                          <v-toolbar flat>
                              <v-toolbar-title><b>Dokumen Perseroan</b></v-toolbar-title>
                              <v-divider
                                class="mx-4"
                                inset
                                vertical
                              >
                              </v-divider>
                              <v-spacer></v-spacer>
                              <v-dialog
                                v-model="dialog"
                                max-width="800px"
                                scrollable
                              >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                color="#fec5bb"
                                class="mb-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="createDok()"
                                >
                                Tambah Dokumen
                                </v-btn>
                              </template>
                              <v-card>
                                <v-toolbar dark color="#fec5bb">
                                  <v-btn icon dark @click="dialog = false">
                                    <v-icon style="color: #001219;">mdi-close</v-icon>
                                  </v-btn>
                                  <v-toolbar-title style="color: #001219;">{{ formTitle }}</v-toolbar-title>
                                  <v-spacer></v-spacer>
                                </v-toolbar>
                                <v-progress-linear v-show="progressBar"
                                  indeterminate
                                  color="yellow darken-2"
                                  height="25"
                                ></v-progress-linear>
                                <v-card-title>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                      <v-form
                                        ref="formDok"
                                        v-model="valid"
                                        lazy-validation
                                      >
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                sm="12"
                                                md="12"
                                            >
                                              <div class="form-group">
                                                <label>Nama Dokumen</label>
                                                <v-text-field
                                                v-model="editedItem.nama_dokumen"
                                                id="nama_dokumen"
                                                label="Nama Dokumen"
                                                clearable
                                                solo
                                                ></v-text-field>
                                              </div>
                                            </v-col>
                                            <v-col
                                              cols="6"
                                              sm="6"
                                              md="6"
                                              class="pb-0 mb-0"
                                              hidden
                                            >
                                            <div class="form-group">
                                              <label>Tanggal Terima Dokumen</label>
                                              <v-menu
                                                v-model="menu5"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                              >
                                                <template v-slot:activator="{ on, attrs }">
                                                  <v-text-field
                                                    v-model="editedItem.tgl_terima_baru"
                                                    label="Tanggal Terima Dokumen"
                                                    prepend-icon="mdi-calendar"
                                                    solo
                                                    v-bind="attrs"
                                                    v-on="on"
                                                  ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                  v-model="tgl_terima_sementara"
                                                  @input="menu5 = false"
                                                  id="tgl_terima"
                                                ></v-date-picker>
                                              </v-menu>
                                            </div>
                                            </v-col>
                                            <v-col
                                              cols="6"
                                              sm="6"
                                              md="6"
                                              class="pb-0 mb-0"
                                              hidden
                                            >
                                            <div class="form-group">
                                              <label>Berlaku Sampai Dengan</label>
                                              <v-menu
                                                v-model="menu6"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                              >
                                                <template v-slot:activator="{ on, attrs }">
                                                  <v-text-field
                                                    v-model="editedItem.tgl_exp_baru"
                                                    label="Berlaku Sampai Dengan"
                                                    prepend-icon="mdi-calendar"
                                                    solo
                                                    v-bind="attrs"
                                                    v-on="on"
                                                  ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                  v-model="tgl_exp_sementara"
                                                  @input="menu6 = false"
                                                  id="tgl_exp"
                                                ></v-date-picker>
                                              </v-menu>
                                            </div>
                                            </v-col>
                                            <!-- <div class="form-group"> -->
                                              <!-- <label>Pilih File</label> -->
                                              <v-col
                                                  cols="6"
                                                  sm="6"
                                                  md="6"
                                              >
                                              <div class="form-group">
                                                <v-file-input
                                                  v-show="!alert_file"
                                                  label="Sumber File"
                                                  accept="file*"
                                                  id="source_upload"
                                                  v-model="editedItem.source_upload"
                                                  prepend-icon="mdi-file"
                                                  @click:clear="removeInputPath"
                                                  @change="onFileChange"
                                                  ref="inputFile"
                                                  solo
                                                >
                                              </v-file-input>
                                                <v-alert
                                                  v-model="alert_file"
                                                  dark
                                                  dismissible
                                                  border="left"
                                                  color="deep-blue accent-4"
                                                  v-show="alert_file"
                                                ><span>{{editedItem.nama_file_conv}}</span>
                                                  <template v-slot:close="{toggle}">
                                                    <v-btn @click="doHapusFile(toggle)">Hapus File</v-btn>
                                                  </template>
                                                </v-alert>
                                              </div>
                                              </v-col>
                                              <v-col
                                                cols="6"
                                                sm="6"
                                                md="6"
                                                hidden
                                              >
                                                <div class="form-group">
                                                  <!-- <label>Berlaku Sampai Dengan</label> -->
                                                    <v-checkbox
                                                        v-model="editedItem.reminder"
                                                        label="Ingatkan"
                                                        color="indigo darken-3"
                                                        hide-details
                                                    ></v-checkbox>
                                                </div>
                                              </v-col>
                                            <!-- </div> -->
                                        </v-row>
                                      </v-form>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                        <v-btn
                                            rounded
                                            color="#f28482"
                                            @click="close"
                                        >
                                            Batal
                                        </v-btn>
                                        <v-btn
                                            rounded
                                            color="#fec5bb"
                                            @click="saveDok(editedItem)"
                                        >
                                            Simpan
                                        </v-btn>
                                </v-card-actions>
                              </v-card>
                              </v-dialog>
                                  <v-dialog v-model="dialogDelete" transition="dialog-bottom-transition" max-width="300">
                                      <v-card>
                                          <v-card-title class="text-h5">Peringatan !</v-card-title>
                                          <v-card-text class="text-h6">Hapus Data ?</v-card-text>
                                          <v-card-actions>
                                          <v-spacer></v-spacer>
                                          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                                          <v-btn color="#ffa69e" text @click="deleteItemConfirm(editedItem.id)">OK</v-btn>
                                          <v-spacer></v-spacer>
                                          </v-card-actions>
                                      </v-card>
                                  </v-dialog>
                          </v-toolbar>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn
                                rounded
                                small
                                class="col-md-12 mt-2 mr-2 mb-2"
                                @click="editItem(item)"
                                color="#fec5bb"
                            >
                                Edit Data
                            </v-btn>
                            <v-btn
                                rounded
                                class="col-md-12 mb-2"
                                small
                                @click="deleteItem(item)"
                                color="#f28482"
                            >
                                Hapus Data
                            </v-btn>
                        </template>
                        
                        <template v-slot:no-data>
                            <!-- <v-btn
                                color="primary"
                                @click="initialize"
                            >
                                Reset
                            </v-btn> -->
                        </template>
                      </v-data-table>
                    </div>
                    <!--end: Wizard Step 2-->

                    <!--begin: Wizard Actions -->
                      <div class="d-flex justify-content-between border-top pt-10" >
                        <div class="mr-2">
                          <v-btn
                              rounded
                              color="#ffa69e"
                              style="border-radius: 30px; border: 2px solid white; font-size: 13px; height: 50px;"
                              @click="goTable()"
                          >
                              Kembali Ke Table
                          </v-btn>
                        </div>
                      <div>
                         <v-btn
                              rounded
                              color="#fec5bb"
                              style="border-radius: 30px; border: 2px solid white; font-size: 13px; height: 50px;"
                              @click="save(formItem)"
                              v-show="loadForm"
                          >
                              Simpan
                          </v-btn>
                      </div>
                    </div>
                    <!--end: Wizard Actions -->
                  <!-- </form> -->
                  <!--end: Wizard Form-->
                </div>
              </div>
            </div>
          </div>
        </v-app>
        <!--end: Wizard Bpdy-->
      </div>
      <!--end: Wizard-->
    </div>
    
  </div>
</template>

<style lang="css">
    .v-application--wrap{ min-height: 0px; }
    .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
        background-color: #ffa69e;
        font-size: 13px;
        vertical-align: inherit;
        margin-top: 5%;
    }
</style>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import Services from "@/core/services/notasis-api/Services";
import storagelocal from "@/core/services/storagelocal.service";

export default {
  name: "Wizard-4",
  data(){
    return{
      valid: true,
      formItem: {
        nama: '',
        tgl_akte_baru: '',
        tgl_akte: '',
        jenis_perseroan: '',
        alamat: '',
        contact: '',
        email: '',
        no_hp: '',
        no_akte: '',
        npwp: '',
        nib: '',
        tgl_sk: '',
        tgl_sk_baru: '',
        no_sk: '',
        tgl_akhir_akte: '',
        tgl_akhir_akte_baru: '',
        reminder: true,
        status: true
      },
      editedItem: {
        id: '',
        nama_dokumen: '',
        tgl_terima: '',
        tgl_terima_baru: '',
        tgl_exp: '',
        tgl_exp_baru: '',
        reminder: 0,
        source_upload: null,
        nama_file: '',
        nama_file_conv: ''
      },
      defaultItem: {
        nama_dokumen: '',
        tgl_terima: '',
        tgl_terima_baru: '',
        tgl_exp: '',
        tgl_exp_baru: '',
        reminder: 0,
        source_upload: null,
        nama_file: '',
        nama_file_conv: ''
      },
      itemJenis: [
        {id: "PT", jenis_perseroan: "PT"},
        {id: "CV", jenis_perseroan: "CV"},
        {id: "Yayasan", jenis_perseroan: "Yayasan"},
        {id: "Perkumpulan", jenis_perseroan: "Perkumpulan"},
        {id: "Firma", jenis_perseroan: "Firma"},
      ],
      idFormBiasa: [
        v => !!v || 'Mohon Diisi !'
      ],
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      tgl_akte_sementara: null,
      tgl_sk_sementara: null,
      tgl_akhir_akte_sementara: null,
      tgl_terima_sementara: null,
      tgl_exp_sementara: null,
      flag: "",
      // ableWizard: true,
      idData: '',
      options: {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false
      },
      headerDokumen: [
        {
          text: 'Id',
          // align: 'start',
          sortable: false,
          value: 'id',
          align: ' d-none'
        },
        { text: 'NO', value: 'number', align: 'center', sortable: false, class:'black--text text--darken-4' },
        { text: 'DOKUMEN', value: 'nama_dokumen', sortable: false, align: 'center', class:'black--text text--darken-4' },
        // { text: 'TGL TERIMA', value: 'tgl_terima_fe', class:'black--text text--darken-4', sortable: false, },
        // { text: 'BERLAKU SAMPAI', value: 'tgl_exp_fe', class:'black--text text--darken-4', sortable: false, },
        // { text: 'INGATKAN', value: 'reminder', class:'black--text text--darken-4', sortable: false, },
        { text: 'NAMA FILE', value: 'nama_file', class:'black--text text--darken-4', sortable: false, },
        { text: 'Aksi', value: 'actions', sortable: false, width: 5, align: 'center', class:'black--text text--darken-4' },
      ],
      dDokumen: [],
      tdDokumen: 0,
      loadingTable: false,
      dialog: false,
      progressBar: false,
      alert_file: false,
      editedIndex: -1,
      dialogDelete: false,
      is_source_remove: 0,  
      baseUrlUpload: ApiService.getBaseUrlUpload(),
      loadForm: false,
      isFirstLoad: true,
      pageOne: false,
      pageTwo: false,
      // dataWizard: '',
      id_create: ''
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Wizard", route: "wizard-1" },
      { title: "Wizard-4" }
    ]);
    
    // console.log(this.$route.query.dPerseroan)
    this.flag = this.$route.query.flag

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v4", {
      startStep: 1, // initial active step number
      clickableSteps: this.flag == "isUpdate" ? true : false // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function(/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on("change", function(/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  watch: {
    options: {
        async handler (val) {
            val
            if(this.isFirstLoad && this.flag == "isUpdate" && this.idData != '') 
                await this.getDataDokumen()
        },
        deep: true,
    },
    tgl_akte_sementara(val){
      this.formItem.tgl_akte = val 
      this.formItem.tgl_akte_baru = this.converter(val)
    },
    tgl_sk_sementara(val){
      this.formItem.tgl_sk = val 
      this.formItem.tgl_sk_baru = this.converter(val)
    },
    tgl_akhir_akte_sementara(val){
      this.formItem.tgl_akhir_akte = val 
      this.formItem.tgl_akhir_akte_baru = this.converter(val)
    },
    tgl_terima_sementara(val){
      this.editedItem.tgl_terima = val 
      this.editedItem.tgl_terima_baru = this.converter(val)
    },
    tgl_exp_sementara(val){
      this.editedItem.tgl_exp = val 
      this.editedItem.tgl_exp_baru = this.converter(val)
    },
    flag(val){
      if(val == 'isCreate'){
        this.isCreate()
      }else if(val == 'isUpdate' && this.isFirstLoad){
        this.idData = this.$route.query.idData
        this.load2()
      }
    },
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Tambah Dokumen' : 'Edit Dokumen'
    },
  },
  methods: {
    submit: function(e) {
      e.preventDefault();
      Swal.fire({
        title: "",
        text: "The application has been successfully submitted!",
        icon: "success",
        confirmButtonClass: "btn btn-secondary"
      });
    },
    isCreate(){
      var date_now = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.tgl_akte_sementara = date_now
      this.formItem.tgl_akte_baru = this.converter(this.tgl_akte_sementara)
      this.tgl_sk_sementara = date_now
      this.formItem.tgl_sk_baru = this.converter(this.tgl_sk_sementara)
      this.tgl_akhir_akte_sementara = date_now
      this.formItem.tgl_akhir_akte_baru = this.converter(this.tgl_akhir_akte_sementara)
      this.tgl_terima_sementara = date_now
      this.editedItem.tgl_terima_baru = this.converter(this.tgl_terima_sementara)
      this.tgl_exp_sementara = date_now
      this.editedItem.tgl_exp_baru = this.converter(this.tgl_exp_sementara)
      this.loadForm = true
    },
    // isUpdate(){
    //   this.getDataPerseroan()
    //   this.getDataDokumen()
    // },
    converter(date){
      var conv_date = new Date(date);
      var dd = String(conv_date.getDate()).padStart(2, '0');
      var mm = String(conv_date.getMonth() + 1).padStart(2, '0'); 
      var yyyy = conv_date.getFullYear();

      return dd + '-' + mm + '-' + yyyy;
    },
    converterBe(date){
      var conv_date = new Date(date);
      var dd = String(conv_date.getDate()).padStart(2, '0');
      var mm = String(conv_date.getMonth() + 1).padStart(2, '0'); 
      var yyyy = conv_date.getFullYear();

      return yyyy + '-' + mm + '-' + dd;
    },
    converterFe(date){
      var conv_date = new Date(date);
      var dd = String(conv_date.getDate()).padStart(2, '0');
      var mm = String(conv_date.getMonth() + 1).padStart(2, '0'); 
      var yyyy = conv_date.getFullYear();

      return dd + '-' + mm + '-' + yyyy;
    },
    async save (data_save){
      var saveObj = {}
      var _this = this
      var wizard = new KTWizard('kt_wizard_v4', {
        startStep: 1, // initial active step number
        clickableSteps: this.flag == "isUpdate" ? true : false
      });
      if( _this.$refs.form.validate()){
        if(this.flag == 'isCreate'){
          saveObj = Object.assign({}, data_save)
          saveObj.reminder = this.formItem.reminder == false ? 0 : 1
          saveObj.status = this.formItem.status == false ? 0 : 1
          saveObj.UID = storagelocal.getLocalStorage('UID')
          saveObj.Token = storagelocal.getLocalStorage('token')
          saveObj.Trigger = 'C'
        }else{
          saveObj = Object.assign({}, data_save)
          saveObj.reminder = this.formItem.reminder == false ? 0 : 1
          saveObj.status = this.formItem.status == false ? 0 : 1
          saveObj.UID = storagelocal.getLocalStorage('UID')
          saveObj.Token = storagelocal.getLocalStorage('token')
          saveObj.Trigger = 'U'
          saveObj.id = this.idData
        }
        Promise.all([await this.saveAll(saveObj)])
          .then((results8)=>{
          results8
          this.loadForm = false
          this.pageOne = false
          wizard.goNext()
          // this.loadPage()
        })
      }
    },
    saveAll(saveObj){
      return new Promise(resolve => {
          resolve, saveObj
            // this.ableWizard = false;
            Services.PostData(ApiService, "m_perseroan", saveObj, response=>{
              resolve(response.data);
              if(response.status == 1000){
                // this.dataWizard = "action-next"
                if(response.data[0] != null){
                  this.id_create = response.data[0].id
                }
                Swal.fire({
                    title: "Berhasil",
                    text: "Sukses Menambah Data",
                    icon: "success",
                    heightAuto: false,
                    timer: 2000
                });
              }else{
                Swal.fire({
                    title: "Berhasil",
                    text: "Gagal Menambah Data",
                    icon: "info",
                    heightAuto: false,
                    timer: 2000
                });
              }
            }, err =>{
              err
            }) 
        });
    },
    getDataPerseroan(){
      return new Promise(resolve => {
      var mydata = {
          UID 		        : storagelocal.getLocalStorage('UID'),
          Token		        : storagelocal.getLocalStorage('token'),
          Trigger	        : 'R',
          options         : this.options,
          id              : this.idData
      }
      this.loadForm = false
      Services.PostData(ApiService, "m_perseroan", mydata, async response=>{
        if(response.status == 1000){
          this.loadForm = true
          var responseCace = response.data[0]
          this.formItem = Object.assign({}, responseCace)
          this.tgl_akte_sementara = (new Date(Date.parse(responseCace.tgl_akte) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.formItem.tgl_akte_baru = this.converterFe(responseCace.tgl_akte)
          this.tgl_sk_sementara = (new Date(Date.parse(responseCace.tgl_sk) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.formItem.tgl_sk_baru = this.converterFe(responseCace.tgl_sk)
          this.tgl_akhir_akte_sementara = (new Date(Date.parse(responseCace.tgl_akhir_akte) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.formItem.tgl_akhir_akte_baru = this.converterFe(responseCace.tgl_akhir_akte)
          this.formItem.reminder = responseCace.reminder == '1' ? true : false
          this.formItem.status = responseCace.status == '1' ? true : false
        }else{
          this.loadForm = false
          Swal.fire({
              title: "Gagal",
              text: "Gagal Memuat Data",
              icon: "warning",
              heightAuto: false,
          });
        }
          resolve(responseCace);
          }, err =>{
          err
          })
      });
    },
    getDataDokumen(){
      this.dDokumen = []
      return new Promise(resolve => {
      var mydata = {
          UID 		        : storagelocal.getLocalStorage('UID'),
          Token		        : storagelocal.getLocalStorage('token'),
          Trigger	        : 'R',
          options         : this.options,
          id_perseroan    : this.idData == '' ? this.id_create : this.idData
      }
        this.loadingTable = true
        Services.PostData(ApiService, "t_dokumen_perseroan", mydata, async response=>{
          if(response.status == 1000){
            var responseCace = response.data
              for (let j = 0; j < response.data.length; j++) {
                var row_data = response.data[j];
                row_data.buttonTable = false
                if(row_data.nama_file != '' && row_data.nama_file != null){
                  row_data.buttonTable = true
                }
                row_data.reminder_fe = row_data.reminder == '0' ? 'Tidak' : 'Ya'
                row_data.tgl_terima_fe = this.converterFe(row_data.tgl_terima)
                row_data.tgl_exp_fe = this.converterFe(row_data.tgl_exp)
                if(row_data.nama_file != ''&& row_data.nama_file != null){
                  var conv = row_data.nama_file.split('/')
                  row_data.nama_file_conv = conv[1]
                }
              }
            this.dDokumen = response.data
            this.tdDokumen = response.total
            // this.editedItem = Object.assign({}, responseCace)
            // this.tgl_terima_sementara = (new Date(Date.parse(responseCace.tgl_terima) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            // this.editedItem.tgl_terima = this.converterFe(responseCace.tgl_terima)
            // this.tgl_exp_sementara = (new Date(Date.parse(responseCace.tgl_exp) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            // this.editedItem.tgl_exp = this.converterFe(responseCace.tgl_exp)
            // this.editedItem.reminder = responseCace.reminder == '1' ? true : false
          }
          this.loadingTable = false
          resolve(responseCace);
          }, err =>{
          err
        })
      });
    },
    removeInputPath: function (e) {
      e
      this.$refs.inputFile.reset();
    },
    onFileChange(e) {
      this.alert_file = false
      if(!e)
      return
    },
    // close () {
    //   this.dialog = false
    //   this.$refs.form.resetValidation()
    //   this.$nextTick(() => {
    //     this.editedItem = Object.assign({}, this.tableDefaultItem)
    //     this.editedIndex = -1
    //   })
    //   this.alert_file = false
    // },
    // closeDelete () {
    //   this.dialogDelete = false
    //   this.$nextTick(() => {
    //     this.editedItem = Object.assign({}, this.defaultItem)
    //     this.editedIndex = -1
    //   })
    // },
    // async deleteItem (item){
    //   this.editedIndex = this.posts.indexOf(item)
    //   this.editedItem = Object.assign({}, item)
    //   this.dialogDelete = true
    // },
    doHapusFile(toggle){
      toggle()
      this.editedItem.source_upload = null
      this.editedItem.nama_file = ''
      this.is_source_remove = 1
    },
    async editItem (item) {
      this.alert_file = false
      this.is_source_remove = 0

      this.editedIndex = this.dDokumen.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.tgl_terima_sementara = (new Date(Date.parse(item.tgl_terima) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.editedItem.tgl_terima_baru = this.converterFe(item.tgl_terima)
      this.editedItem.tgl_terima = this.converterBe(item.tgl_terima)
      this.tgl_exp_sementara = (new Date(Date.parse(item.tgl_exp) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.editedItem.tgl_exp_baru = this.converterFe(item.tgl_exp)
      this.editedItem.tgl_exp = this.converterBe(item.tgl_exp)
      this.dialog = true

      if(this.editedItem.nama_file != null && this.editedItem.nama_file != ''){
        this.alert_file = true
      }
    },
    saveAllDok(saveObj){
      return new Promise(resolve => {
            Services.PostData(ApiService, "t_dokumen_perseroan", saveObj, response=>{
              resolve(response.data);
              if(response.status == 1000){
                Swal.fire({
                    title: "",
                    text: "Sukses Menambah Data",
                    icon: "success",
                    heightAuto: false,
                    timer: 1500
                });
              }else{
                Swal.fire({
                    title: "",
                    text: "Gagal Menambah Data",
                    icon: "info",
                    heightAuto: false,
                    timer: 1500
                });
              }
            }, err =>{
              err
            })
        });
    },
    async saveDok (data_save){
      var saveObj = {}
      var _this = this
      if( _this.$refs.formDok.validate()){
        if (this.editedIndex > -1) {
          saveObj = Object.assign({}, data_save)
          // saveObj.MenuUrl = this.$router.currentRoute.path
          saveObj.file_dokumen = null
          saveObj.id_perseroan = this.idData == '' ? this.id_create : this.idData
          saveObj.UID = storagelocal.getLocalStorage('UID'),
          saveObj.Token = storagelocal.getLocalStorage('token'),
          saveObj.Trigger = 'U'
          saveObj.is_source_remove = this.is_source_remove
          saveObj.reminder = _this.editedItem.reminder == false ? 0 : 1
        } else {
          saveObj = Object.assign({}, data_save)
          saveObj.id_perseroan = this.idData == '' ? this.id_create : this.idData
          // saveObj.MenuUrl = this.$router.currentRoute.path
          saveObj.UID = storagelocal.getLocalStorage('UID'),
          saveObj.Token = storagelocal.getLocalStorage('token'),
          saveObj.Trigger = 'C'
          saveObj.is_source_remove = this.is_source_remove
          saveObj.reminder = _this.editedItem.reminder == false ? 0 : 1
        }
        this.progressBar = true
          Promise.all([await this.saveAllDok(saveObj)])
            .then((results8)=>{
            results8
            this.progressBar = false
            this.close()
            this.getDataDokumen()
          })
      }
      // this.close()
    },
    close () {
      this.dialog = false
      this.$refs.form.resetValidation()
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.alert_file = false
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async deleteItem (item){
      this.editedIndex = this.dDokumen.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
      // this.close()
    },
    async deleteItemConfirm (id) {
      var deleteObj = {
        //MenuUrl: this.$router.currentRoute.path
        }
      deleteObj.UID = storagelocal.getLocalStorage('UID'),
      deleteObj.Token = storagelocal.getLocalStorage('token'),
      deleteObj.Trigger = 'D'
      deleteObj.id_perseroan = this.idData == '' ? this.id_create : this.idData
      deleteObj.id = id
    
      Promise.all([await this.del(deleteObj)])
        .then((resultsdel)=>{
        resultsdel
            
        this.closeDelete()
        this.getDataDokumen()
      })
    },
    del(deleteObj){
      return new Promise(resolve => {
          Services.PostData(ApiService, "t_dokumen_perseroan", deleteObj, response=>{
            resolve(response.data);
            if(response.status == 1000){
              Swal.fire({
                  title: "",
                  text: "Sukses Hapus Data",
                  icon: "success",
                  heightAuto: false,
                  timer: 1500
              });
            }else if(response.status == 2002){
              Swal.fire({
                  title: "",
                  text: "Data Tidak Dapat Di Hapus",
                  icon: "info",
                  heightAuto: false,
                  timer: 1500
              });
            }else{
              Swal.fire({
                  title: "",
                  text: "Gagal Hapus Data",
                  icon: "info",
                  heightAuto: false,
                  timer: 1500
              });
            }

          }, err =>{
            err
          })
      });
    },
    openWeb(link){
      // if(!link.match(/\.pdf/)){
        window.open(this.baseUrlUpload + '/' + link, '_blank').focus()
      // }
    },
    createDok(){
      var date_now = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.tgl_terima_sementara = date_now
      this.editedItem.tgl_terima_baru = this.converter(this.tgl_terima_sementara)
      this.editedItem.tgl_terima = this.converterBe(this.tgl_terima_sementara)
      this.tgl_exp_sementara = date_now
      this.editedItem.tgl_exp_baru = this.converter(this.tgl_exp_sementara)
      this.editedItem.tgl_exp = this.converterBe(this.tgl_exp_sementara)
      this.dialog = true
    },
    goTable(){
      this.$router.push({name: 'tableperseroan'})
    },
    async load2(){
      Promise.all([
                    await this.getDataDokumen(),
                    await this.getDataPerseroan()
                  ])
      .then( (results)=> {
       
        this.isFirstLoad = false
        results
      })
    },
    stepOne(){
      this.loadForm = true
      this.pageOne = true
      this.pageTwo = false
    },
    stepTwo(){
      this.loadForm = false
      this.pageOne = false
      this.pageTwo = true
    },
  }
};
</script>
